import React, { Fragment } from 'react';
import Sticky from 'react-stickynode';
import { ThemeProvider } from 'styled-components';
import { saasTheme } from 'common/src/theme/saas';
import { ResetCSS } from 'common/src/assets/css/style';
import {
  GlobalStyle,
  ContentWrapper,
} from 'common/src/containers/Saas/saas.style';
import Navbar from 'common/src/containers/Saas/Navbar';
import Footer from 'common/src/containers/Saas/Footer';
import ConfrontaSection from 'common/src/containers/Saas/ConfrontaSection';
import FaqSection from 'common/src/containers/Saas/FaqSection';
import { DrawerProvider } from 'common/src/contexts/DrawerContext';
import SEO from '../components/seo';

import { useCookie } from '@use-hook/use-cookie';

export default () => {
  // eslint-disable-next-line
  const [email, setEmail] = useCookie('email');

  if (!email && typeof window !== 'undefined') {
    window.location.href = '/';
  }

  return (
    <ThemeProvider theme={saasTheme}>
      <Fragment>
        <SEO
          title="SignorPOS | Confronta le soluzioni POS e risparmia"
          description="Scopri qual è il POS più conveniente per il tuo business. Per tabaccai, temporary store, hotel, alberghi, ristoranti e negozi di abbigliamento"
        />
        <ResetCSS />
        <GlobalStyle />
        <ContentWrapper>
          <Sticky top={0} innerZ={9999} activeClass="sticky-nav-active">
            <DrawerProvider>
              <Navbar button={false} />
            </DrawerProvider>
          </Sticky>
          <ConfrontaSection />
          <FaqSection />
          <Footer />
        </ContentWrapper>
      </Fragment>
    </ThemeProvider>
  );
};
