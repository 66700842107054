import styled from 'styled-components';
import { themeGet } from 'styled-system';

export const PricingReadMore = styled.div`
  flex-basis: 0;
  @media screen and (min-width: 767px) {
    flex-basis: 100%;
  }
`;

export const ReadMoreHeader = styled.div`
  user-select: none;
  cursor: pointer;
  display: flex;
  justify-content: center;
  color: ${themeGet('colors.primary')};
  margin-bottom: 1rem;
`;
