export const marks = {
  0: 0,
  600000: 10000,
  1200000: 20000,
  1800000: 30000,
  2800000: 50000,
  4000000: 100000,
  5200000: 200000,
  6500000: 300000,
  7500000: 500000,
  8800000: 1000000,
  10000000: 5000000,
};

export const getMarks = (divider = 1) => {
  return Object.entries(marks)
    .map(([key, val]) => [key, val])
    .reduce((acc, [key, val]) => {
      acc[key] = {
        value: val / divider,
        label: `${(val / divider).toLocaleString('it-IT')}€`,
      };
      return acc;
    }, {});
};

export const getKeys = () => Object.keys(getMarks()).map(e => Number(e));

export const getMaxValue = values => {
  if (!values || values.length === 0) return false;

  let final;
  if (typeof values === 'object') {
    final = Object.values(values).map(e => e.value);
  }

  if (Array.isArray(values)) {
    final = values;
  }

  return Math.max(...final);
};

export const getMinValue = values => {
  if (!values || values.length === 0) return false;

  let final;
  if (typeof values === 'object') {
    final = Object.values(values).map(e => e.value);
  }

  if (Array.isArray(values)) {
    final = values;
  }

  return Math.min(...final);
};

export const isKeyInRange = key => {
  return isInRange(key, getKeys());
};

export const isValueInRange = (value, divider = 1) => {
  return isInRange(value, getMarks(divider));
};

export const isInRange = (value, range) => {
  if (!range) throw Error('Range is required');

  return getMaxValue(range) >= value && getMinValue(range) <= value;
};

function getIndexOfInsertedElement(arr, num) {
  // Insert num into arr, creating a new array.
  let newArray = arr.concat(num);
  //             [40, 60].concat(50)
  //             [40, 60, 50]

  // Sort the new array from least to greatest.
  newArray.sort((a, b) => a - b);
  // [40, 60, 50].sort((a, b) => a - b)
  // [40, 50, 60]

  // Return the index of num which is now
  // in the correct place in the new array.
  return newArray.indexOf(num);
  // return [40, 50, 60].indexOf(50)
  // 1
}

export const getKeyBounds = key => {
  if (!isKeyInRange(key)) return false;

  const keys = getKeys();

  const index = getIndexOfInsertedElement(keys, key);

  if (index === 0) return [keys[index], keys[index + 1]];

  return [keys[index - 1], keys[index]];
};

export const getValueBounds = (value, divider = 1) => {
  if (!isValueInRange(value)) return false;

  const values = Object.values(getMarks(divider))
    .map(e => e.value)
    .sort((a, b) => a - b);

  const index = getIndexOfInsertedElement(values, value);

  if (index === 0) return [values[index], values[index + 1]];

  return [values[index - 1], values[index]];
};

export const getMarkValue = (key, divider = 1) => {
  if (key === 0) return getMarks(divider)[0].value;

  if (!key) return undefined;
  if (!isKeyInRange(key)) return undefined;

  const mark = getMarks(divider)[key];
  if (mark) return mark.value;

  // It means we are in the middle of the two, so we need to calc the real value between two

  const [lowerKey, upperKey] = getKeyBounds(key);

  const [lowerValue, upperValue] = [
    getMarkValue(lowerKey, divider),
    getMarkValue(upperKey, divider),
  ];

  const round = divider === 1 ? 1000 : 50;
  const result =
    Math.round(
      (lowerValue +
        ((upperValue - lowerValue) / (upperKey - lowerKey)) *
          (key - lowerKey)) /
        round
    ) * round;

  return result;
};

// Given a amount it will return upper or lower key or precise key if value is one listed in marks
export const getMarkKey = (value, divider) => {
  if (value === 0) return 0;
  if (value < 0) return 0;

  if (!value) return undefined;
  if (!isValueInRange(value, divider)) {
    return getMaxValue(getKeys());
  }

  const marks = getMarks(divider);

  const precise = Object.entries(marks).find(
    ([key, { value: val }]) => val === value
  );
  if (precise) return Number(precise[0]);

  const [lowerValue, upperValue] = getValueBounds(value, divider);
  const [lowerKey, upperKey] = [
    getMarkKey(lowerValue, divider),
    getMarkKey(upperValue, divider),
  ];

  return (
    lowerKey +
    ((value - lowerValue) / (upperValue - lowerValue)) * (upperKey - lowerKey)
  );
};
