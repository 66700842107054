import React, { useState, useEffect, useMemo, useRef } from 'react';
import PropTypes from 'prop-types';
import Icon from 'react-icons-kit';

import Box from 'reusecore/src/elements/Box';
import Text from 'reusecore/src/elements/Text';
import Heading from 'reusecore/src/elements/Heading';
import Button from 'reusecore/src/elements/Button';
import Radio from 'reusecore/src/elements/Radio';
import Container from '../../../components/UI/Container';
import ReadMore from '../../../components/ReadMore';
import ProgressBar from '../../../components/ProgressBar';
import RangeBoxOld from 'reusecore/src/elements/Range-with-RC-SLIDE';
import Tooltip from 'reusecore/src/elements/Tooltip';
import Image from 'reusecore/src/elements/Image';

import ContentLoader from 'react-content-loader';

import 'react-step-progress-bar/styles.css';

import { getBestSolutions, getPOSType, posTypes } from '../../../data/Saas';

import useQueryString from '../../../lib/useQueryString';

import { getMarkValue, getMarks, getMarkKey } from './marks';

import PricingTable, {
  PricingHead,
  PricingPrice,
  PricingButton,
  PricingList,
  ListItem,
  RangeBoxWrapper,
  ReadMoreItem,
  ReadMoreColumn,
  ReadMoreWrapper,
  Ribbon
} from './confronta.style';

import { checkmark } from 'react-icons-kit/icomoon/checkmark';
import { cross } from 'react-icons-kit/icomoon/cross';

import useCookie from '@use-hook/use-cookie';
import axios from 'axios';
import { useDebouncedCallback } from 'use-debounce';
import CheckBox from 'reusecore/src/elements/Checkbox';

const useActivePosType = def => {
  const [activePosTypes, setActivePosTypes] = useState(def);

  const removeActivePosType = toBeRemoved => {
    setActivePosTypes(activePosTypes.filter(value => value !== toBeRemoved));
  };

  const addActivePosType = toBeAdded => {
    if (checkIfActive(toBeAdded)) return;

    setActivePosTypes([...activePosTypes, toBeAdded]);
  };

  const toggleActivePosType = el => {
    if (checkIfActive(el)) {
      removeActivePosType(el);
    } else {
      addActivePosType(el);
    }
  };

  const checkIfActive = el => activePosTypes.indexOf(el) > -1;

  return {
    activePosTypes,
    setActivePosTypes,
    addActivePosType,
    removeActivePosType,
    toggleActivePosType,
    checkIfActive
  };
};

const ConfrontaSection = ({
  sectionWrapper,
  row,
  col,
  secTitleWrapper,
  sliderWrapper,
  secHeading,
  secText,
  nameStyle,
  descriptionStyle,
  priceStyle,
  priceLabelStyle,
  buttonStyle,
  buttonFillStyle,
  listContentStyle,
  readMoreStyle
}) => {
  const [type] = useQueryString('t', 'a');

  const isYearActive = type === 'a';
  const multiplier = type === 'a' ? 12 : 1;
  const divider = type === 'a' ? 1 : 20;

  const [amount, setAmount] = useQueryString('q', 0);
  const [cost, setCost] = useState('c');
  const [acceptsBancomat, setAcceptsBancomat] = useState('n');

  const [debouncedCallback] = useDebouncedCallback(
    // function
    value => {
      setAmount(getMarkValue(value, divider));
    },
    // delay in ms
    100
  );

  const {
    checkIfActive,
    toggleActivePosType,
    activePosTypes
  } = useActivePosType(Object.values(posTypes).map(pt => pt.id));

  const solutions = useMemo(() => {
    return getBestSolutions(amount / multiplier).filter(solution => {
      let shouldShow = true
      if(!activePosTypes.some(r => solution.posTypes.includes(r))) {
        shouldShow = false
      }

      if(acceptsBancomat === 'y' && solution.acceptedPaymentNetworksLabel.toLowerCase().indexOf('bancomat') === -1) {
        shouldShow = false
      }

      return shouldShow
    });
  }, [amount, activePosTypes, multiplier, acceptsBancomat]);

  const [percent, setPercent] = useState(50);

  useEffect(() => {
    setTimeout(() => {
      setPercent(100);
    }, 1000);
  }, []);

  return (
    <Box {...sectionWrapper} id="pricing_section">
      <Container>
        <ProgressBar percent={percent} />
        <Box {...secTitleWrapper}>
          <Text {...secText} />
          <Heading {...secHeading} />
          <Box {...sliderWrapper}>
            <RangeBoxWrapper>
              {typeof window !== 'undefined' && (
                <RangeBoxOld
                  onChange={key => {
                    debouncedCallback(key);
                  }}
                  disabled={false}
                  min={0}
                  max={10000000}
                  dots={false}
                  vertical={false}
                  marks={getMarks(divider)}
                  rangeDefaultValue={getMarkKey(amount, divider)}
                  slideDefaultValue={getMarkKey(amount, divider)}
                  tipFormatter={key =>
                    `${getMarkValue(key, divider).toLocaleString()}€`
                  }
                />
              )}
            </RangeBoxWrapper>
          </Box>
          <Heading
            {...secHeading}
            content={`Con ${Number(amount).toLocaleString()}€ di transato ${
              isYearActive === true ? `all'anno` : `al mese`
            } il POS più conveniente è:`}
            fontSize={['24px', '24px', '34px', '34px', '44px']}
          />
          <Box
            {...row}
            boxShadow="#ccc 0 0 10px 0px"
            justifyContent="space-between"
            p={20}
          >
            <Box mb={[4, 4, 4, 0]}>
              <Text
                mr="10px"
                mb="7px"
                fontWeight={600}
                color="headingColor"
                content="Scegli quali tipi di POS visualizzare:"
              />
              {posTypes.map(({ id, label, description }, i) => (
                <Tooltip
                  position="top"
                  message={description}
                  style={{ letterSpacing: 0 }}
                  bubbleSize={{ minWidth: '210px' }}
                >
                  <CheckBox
                    key={id}
                    id={`posType_${id}`}
                    labelText={label}
                    name={`posType[${id}]`}
                    value={`${id}`}
                    checked={checkIfActive(id)}
                    onChange={e =>
                      toggleActivePosType(parseInt(e.target.value))
                    }
                    mr={'10px'}
                  />
                </Tooltip>
              ))}
            </Box>
            {process.env.GATSBY_COMMISSION_CALC && (
              <Box>
                <Text
                  mr="10px"
                  mb="7px"
                  fontWeight={600}
                  color="headingColor"
                  content="Visualizza per:"
                />
                <Box>
                  <Radio
                    mr={2}
                    labelText="Costo €"
                    value="c"
                    name="type"
                    checked={cost === 'c'}
                    onClick={() => setCost('c')}
                    readOnly
                  />
                  <Radio
                    labelText="Costo %"
                    value="f"
                    name="type"
                    checked={cost === 'f'}
                    onClick={() => setCost('f')}
                    readOnly
                  />
                </Box>
              </Box>
            )}
            <Box>
              <Text
                mr="10px"
                mb="7px"
                fontWeight={600}
                color="headingColor"
                content="Nascondi offerte che non accettano PagoBancomat"
              />
              <Box>
                <Radio
                  mr={2}
                  labelText="Sì"
                  value="y"
                  name="type"
                  checked={acceptsBancomat === 'y'}
                  onClick={() => setAcceptsBancomat('y')}
                  readOnly
                />
                <Radio
                  labelText="No"
                  value="n"
                  name="type"
                  checked={acceptsBancomat === 'n'}
                  onClick={() => setAcceptsBancomat('n')}
                  readOnly
                />
              </Box>
            </Box>
          </Box>
        </Box>
        <Box {...row} style={{ minHeight: '100%', alignItems: 'stretch' }}>
          <ListOfSolutions
            solutions={solutions}
            secText={secText}
            descriptionStyle={descriptionStyle}
            row={row}
            amount={amount}
            cost={cost}
            priceStyle={priceStyle}
            priceLabelStyle={priceLabelStyle}
            buttonFillStyle={buttonFillStyle}
            buttonStyle={buttonStyle}
            listContentStyle={listContentStyle}
            readMoreStyle={readMoreStyle}
          />
        </Box>
      </Container>
    </Box>
  );
};

const ImageLoader = props => (
  <ContentLoader
    speed={2}
    backgroundColor="#f3f3f3"
    foregroundColor="#ecebeb"
    {...props}
    width={180}
    height={67}
    viewBox="0 0 180 67"
  >
    <rect x="0" y="0" rx="0" ry="0" width="180" height="67" />
  </ContentLoader>
);

const HeadLoader = props => (
  <ContentLoader
    speed={2}
    backgroundColor="#f3f3f3"
    foregroundColor="#ecebeb"
    {...props}
    width={75}
    height={30}
    viewBox="0 0 75 30"
  >
    <rect x="0" y="0" rx="0" ry="0" width="75" height="12" />
    <rect x="0" y="18" rx="0" ry="0" width="75" height="12" />
  </ContentLoader>
);

const PriceLoader = props => (
  <ContentLoader
    speed={2}
    backgroundColor="#f3f3f3"
    foregroundColor="#ecebeb"
    {...props}
    width={180}
    height={48}
    viewBox="0 0 180 48"
  >
    <rect x="0" y="0" rx="0" ry="0" width="180" height="7" />
    <rect x="0" y="12" rx="0" ry="0" width="180" height="21" />
    <rect x="0" y="38" rx="0" ry="0" width="180" height="10" />
  </ContentLoader>
);

const ButtonLoader = props => (
  <ContentLoader
    speed={2}
    backgroundColor="#f3f3f3"
    foregroundColor="#ecebeb"
    {...props}
    width={250}
    height={52}
    viewBox="0 0 250 52"
  >
    <rect x="0" y="0" rx="0" ry="0" width="250" height="52" />
  </ContentLoader>
);

const TextLoader = props => (
  <ContentLoader
    speed={2}
    backgroundColor="#f3f3f3"
    foregroundColor="#ecebeb"
    {...props}
    height={15}
    viewBox="0 0 250 15"
  >
    <rect x="0" y="0" rx="0" ry="0" width="85%" height="15" />
  </ContentLoader>
);

const ListOfSolutions = ({
  solutions,
  secText,
  descriptionStyle,
  row,
  amount,
  cost,
  priceStyle,
  priceLabelStyle,
  buttonFillStyle,
  listContentStyle,
  readMoreStyle,
  buttonStyle
}) => {
  const [transactionUUIDCookie] = useCookie('transactionUUID');
  const [, setSelectedOfferUUID] = useCookie('selectedOfferUUID');
  const [transactionUUIDQ] = useQueryString('id');
  const transactionUUID = useMemo(
    () => transactionUUIDQ || transactionUUIDCookie,
    [transactionUUIDQ, transactionUUIDCookie]
  );
  const [isLoading, setIsLoading] = useState(false);

  if (solutions.length === 0 || typeof window === 'undefined') {
    return (
      <PricingTable className="pricing_table" key={'loader'}>
        <Box
          className="row"
          {...row}
          mb={['40px', '40px', 0]}
          style={{ flexBasis: '18%' }}
        >
          <ImageLoader />
        </Box>
        <PricingHead>
          <HeadLoader />
        </PricingHead>
        <PricingPrice>
          <PriceLoader />
        </PricingPrice>
        <PricingButton>
          <ButtonLoader />
        </PricingButton>
        <PricingList>
          <ListItem key={`pricing-table-list-1`}>
            <TextLoader />
          </ListItem>
          <ListItem key={`pricing-table-list-2`}>
            <TextLoader />
          </ListItem>
          <ListItem key={`pricing-table-list-3`}>
            <TextLoader />
          </ListItem>
          <ListItem key={`pricing-table-list-4`}>
            <TextLoader />
          </ListItem>
        </PricingList>
      </PricingTable>
    );
  }

  return solutions.map(
    (
      {
        vendorName,
        posTypes,
        nextYears,
        firstYear,
        firstMonth,
        nextMonths,
        deviceName,
        offerName,
        firstYearFixedCost,
        yearlySubscriptionFee,
        variableFeesLabels,
        acceptedPaymentNetworksLabel,
        additionalPaymentNetworksLabel,
        tableAdditionalInfo,
        vendorLogo,
        fixedFee,
        logoHeight,
        pageUrl,
        formInOfferPage,
        skipOfferPage,
        offerPage,
        variableFees,
        id,
        oneTimeFees,
        monthlyFees,
        specialFees,
        textFeatures,
        promoMessage,
        broken
      },
      index
    ) => (
      <PricingTable className="pricing_table" key={id} promoMessage={promoMessage}>
        <Box
          className="row"
          {...row}
          mb={['40px', '40px', 0]}
          style={{ flexBasis: '18%' }}
        >
          <Image
            src={vendorLogo}
            width={'auto'}
            alt={`${vendorName} ${deviceName} ${offerName}`}
            maxHeight={logoHeight ? logoHeight : '50px'}
          />
        </Box>
        <PricingHead>
          <Text {...secText} content={deviceName} />
          <Text content={offerName} {...descriptionStyle} />
        </PricingHead>
        <PricingPrice>
          <Text
            content={
              <Tooltip
                position="left"
                message="Comprensivo di tutte le voci di costo, comprese le commissioni"
                style={{ letterSpacing: 0 }}
                bubbleSize={{ minWidth: '210px' }}
              >
                <Text
                  content={
                    cost === 'c'
                      ? 'costo stimato il 1° anno:'
                      : 'commissione media:'
                  }
                  {...priceLabelStyle}
                  letterSpacing={0}
                  fontWeight={400}
                />
                {cost === 'c'
                  ? `${Math.round(firstYear).toLocaleString()}€`
                  : (firstYear / amount).toLocaleString(undefined, {
                      style: 'percent',
                      minimumFractionDigits: 2
                    })}
              </Tooltip>
            }
            {...priceStyle}
            style={{ fontWeight: 700 }}
          />
          <Text
            content={
              cost === 'c'
                ? `${Math.round(
                    nextYears
                  ).toLocaleString()}€ all'anno gli anni successivi`
                : `${(nextYears / amount).toLocaleString(undefined, {
                    style: 'percent',
                    minimumFractionDigits: 2
                  })} gli anni successivi`
            }
            {...priceLabelStyle}
          />
        </PricingPrice>
        {formInOfferPage && !!skipOfferPage && (
          <PricingButton>
            <form
              action={pageUrl}
              method="POST"
              id={`offer_form_${id}`}
              onSubmit={async e => {
                e.preventDefault();

                setIsLoading(true);
                console.log(
                  'Tracking Google Analytics',
                  `${id}:${vendorName}:${offerName}`,
                  index + 1
                );
                if (
                  typeof window !== 'undefined' &&
                  window.ga &&
                  typeof window.ga.getAll === 'function'
                ) {
                  const tracker = window.ga.getAll()[0];
                  tracker.send('event', {
                    eventCategory:
                      index === 0
                        ? 'Offerta più conveniente'
                        : `Offerta ${index + 1}`,
                    eventAction: 'click',
                    eventLabel: `${id}:${vendorName}:${offerName}`,
                    eventValue: id,
                    transport: 'beacon'
                  });
                }

                let response = false;
                response = await axios
                  .post(`${process.env.GATSBY_BACKEND_URL}/hooks/offer`, {
                    amount,
                    offerId: id,
                    vendorName,
                    offerName,
                    vendorLogo,
                    offerPosition: index + 1,
                    transactionUUID,
                    cost: `${Math.round(firstYear).toLocaleString()}€`,
                    offerUrl: offerPage
                      ? `${process.env.GATSBY_HOST}${offerPage}`
                      : pageUrl
                  })
                  .catch(err => {
                    console.error(err);
                    return false;
                  });

                console.log(`Done. Response data`, response.data);

                setIsLoading(false);
                if (response && !broken) {
                  const { uuid, phone, email, axepta_hash } = response.data;
                  console.log(`Setting form params:`, {
                    uuid,
                    phone,
                    email,
                    axepta_hash
                  });
                  document.querySelector(
                    `#offer_form_${id} input[name="id_signor_pos"]`
                  ).value = uuid;
                  document.querySelector(
                    `#offer_form_${id} input[name="email"]`
                  ).value = email;
                  document.querySelector(
                    `#offer_form_${id} input[name="telefono"]`
                  ).value = phone;
                  document.querySelector(
                    `#offer_form_${id} input[name="hash"]`
                  ).value = axepta_hash;
                }

                requestAnimationFrame(() => {
                  console.log(`Submitting form #offer_form_${id}`);
                  document.querySelector(`#offer_form_${id}`).submit();
                });
              }}
            >
              <input type="hidden" name="id_signor_pos" />
              <input type="hidden" name="email" />
              <input type="hidden" name="telefono" />
              <input type="hidden" name="hash" />
              {index === 0 ? (
                <Button
                  {...buttonFillStyle}
                  type="submit"
                  title={
                    isLoading
                      ? 'Carico i dettagli...'
                      : "Vai all'offerta più conveniente"
                  }
                  isLoading={isLoading}
                  loaderColor="#fff"
                  disabled={isLoading}
                />
              ) : (
                <Button
                  {...buttonStyle}
                  type="submit"
                  title="Vai all'offerta"
                  isLoading={isLoading}
                  disabled={isLoading}
                  loaderColor="#fff"
                />
              )}
            </form>
          </PricingButton>
        )}
        {(!formInOfferPage || !skipOfferPage) && (
          <PricingButton
            onClick={event => {
              setIsLoading(true);
              console.log(
                'Tracking Google Analytics',
                `${id}:${vendorName}:${offerName}`,
                index + 1
              );
              if (
                typeof window !== 'undefined' &&
                window.ga &&
                typeof window.ga.getAll === 'function'
              ) {
                const tracker = window.ga.getAll()[0];
                if(typeof tracker !== 'undefined' && typeof tracker.send === 'function') {
                  tracker.send('event', {
                    eventCategory:
                      index === 0
                        ? 'Offerta più conveniente'
                        : `Offerta ${index + 1}`,
                    eventAction: 'click',
                    eventLabel: `${id}:${vendorName}:${offerName}`,
                    eventValue: id,
                    transport: 'beacon'
                  });
                }
              }

              var a = document.createElement('a');
              a.target = '_blank';

              axios
                .post(`${process.env.GATSBY_BACKEND_URL}/hooks/offer`, {
                  amount,
                  offerId: id,
                  vendorName,
                  offerName,
                  vendorLogo,
                  offerPosition: index + 1,
                  transactionUUID,
                  cost: `${Math.round(firstYear).toLocaleString()}€`,
                  offerUrl: offerPage
                    ? `${process.env.GATSBY_HOST}${offerPage}`
                    : pageUrl
                })
                .then(response => {
                  setIsLoading(false);

                  const { uuid } = response.data;
                  setSelectedOfferUUID(uuid);

                  a.href = offerPage ? `${offerPage}?id=${uuid}` : pageUrl;
                  a.click();
                })
                .catch(err => {
                  setIsLoading(false);
                  a.href = offerPage ? `${offerPage}` : pageUrl;
                  a.click();
                });
            }}
          >
            {index === 0 ? (
              <Button
                title={
                  isLoading
                    ? 'Carico i dettagli...'
                    : "Vai all'offerta più conveniente"
                }
                isLoading={isLoading}
                loaderColor="#fff"
                disabled={isLoading}
                {...buttonFillStyle}
              />
            ) : (
              <Button
                title="Vai all'offerta"
                isLoading={isLoading}
                disabled={isLoading}
                loaderColor="#fff"
                {...buttonStyle}
              />
            )}
          </PricingButton>
        )}
        <PricingList>
          {textFeatures &&
            textFeatures.length > 0 &&
            textFeatures.map(({ positive, text }, index) => {
              return (
                <ListItem key={`pricing-table-list-${index + 1}`}>
                  <Icon
                    icon={!positive ? cross : checkmark}
                    className="price_list_icon"
                    size={13}
                    style={{
                      color: !positive ? 'red' : '#18d379'
                    }}
                  />
                  <Text content={text} {...listContentStyle} />
                </ListItem>
              );
            })}
        </PricingList>
        {promoMessage && false && <Ribbon>{promoMessage}</Ribbon>}
        <ReadMore>
          <ReadMoreWrapper>
            <ReadMoreColumn>
              {oneTimeFees &&
                oneTimeFees.map(({ value, label }) => {
                  return (
                    <ReadMoreItem key={label}>
                      {/* <Icon icon={questionCircle} size={17} /> */}
                      <Text content={`${label}: `} {...readMoreStyle} />
                      <Text
                        content={`${value.toLocaleString()}€`}
                        {...listContentStyle}
                      />
                    </ReadMoreItem>
                  );
                })}
              {monthlyFees &&
                monthlyFees.map(({ value, label }) => {
                  return (
                    <ReadMoreItem key={label}>
                      {/* <Icon icon={questionCircle} size={17} /> */}
                      <Text content={`${label}: `} {...readMoreStyle} />
                      <Text
                        content={`${value.toLocaleString()}€`}
                        {...listContentStyle}
                      />
                    </ReadMoreItem>
                  );
                })}
              {specialFees &&
                specialFees.map(({ value, label, valueLabel }) => {
                  return (
                    <ReadMoreItem key={label}>
                      {/* <Icon icon={questionCircle} size={17} /> */}
                      <Text content={`${label}: `} {...readMoreStyle} />
                      <Text
                        htmlContent={
                          valueLabel ? valueLabel : `${value.toLocaleString()}€`
                        }
                        {...listContentStyle}
                      />
                    </ReadMoreItem>
                  );
                })}
              {firstYearFixedCost ? (
                <ReadMoreItem>
                  {/* <Icon icon={questionCircle} size={17} /> */}
                  <Text content="Costo acquisto: " {...readMoreStyle} />
                  <Text
                    content={`${firstYearFixedCost.toLocaleString()}€`}
                    {...listContentStyle}
                  />
                </ReadMoreItem>
              ) : null}
            </ReadMoreColumn>
            <ReadMoreColumn>
              {variableFees.pagoBancomat ||
              (variableFeesLabels && variableFeesLabels.pagoBancomat) ? (
                <ReadMoreItem>
                  {/* <Icon icon={questionCircle} size={17} /> */}
                  <Text
                    content="Commissione PagoBANCOMAT: "
                    {...readMoreStyle}
                  />
                  <Text
                    content={
                      variableFeesLabels && variableFeesLabels.pagoBancomat
                        ? variableFeesLabels.pagoBancomat
                        : `${parseFloat(
                            (variableFees.pagoBancomat * 100).toFixed(2)
                          ).toLocaleString()}%`
                    }
                    {...listContentStyle}
                  />
                </ReadMoreItem>
              ) : (
                ''
              )}
              {variableFees.visa ||
              (variableFeesLabels &&
                variableFeesLabels.visa &&
                variableFeesLabels.visa.credit) ? (
                <ReadMoreItem>
                  {/* <Icon icon={questionCircle} size={17} /> */}
                  <Text
                    content="Commissione carte consumer: "
                    {...readMoreStyle}
                  />
                  <Text
                    content={
                      variableFeesLabels &&
                      variableFeesLabels.visa &&
                      variableFeesLabels.visa.credit
                        ? variableFeesLabels.visa.credit
                        : `${parseFloat(
                            (variableFees.visa.credit * 100).toFixed(2)
                          ).toLocaleString()}%`
                    }
                    {...listContentStyle}
                  />
                </ReadMoreItem>
              ) : (
                ''
              )}
              {variableFees.visa ||
              (variableFeesLabels &&
                variableFeesLabels.visa &&
                variableFeesLabels.visa.commercial) ? (
                <ReadMoreItem>
                  {/* <Icon icon={questionCircle} size={17} /> */}
                  <Text
                    content="Commissione carte commercial: "
                    {...readMoreStyle}
                  />
                  <Text
                    content={
                      variableFeesLabels &&
                      variableFeesLabels.visa &&
                      variableFeesLabels.visa.commercial
                        ? variableFeesLabels.visa.commercial
                        : `${parseFloat(
                            (variableFees.visa.commercial * 100).toFixed(2)
                          ).toLocaleString()}%`
                    }
                    {...listContentStyle}
                  />
                </ReadMoreItem>
              ) : (
                ''
              )}
              <ReadMoreItem>
                {/* <Icon icon={questionCircle} size={17} /> */}
                <Text content="Circuiti accettati: " {...readMoreStyle} />
                <Text
                  content={
                    acceptedPaymentNetworksLabel
                      ? acceptedPaymentNetworksLabel
                      : 'PagoBANCOMAT, VISA, Mastercard, Maestro, VPay, UPI, JCB'
                  }
                  {...listContentStyle}
                />
              </ReadMoreItem>
              <ReadMoreItem>
                {/* <Icon icon={questionCircle} size={17} /> */}
                <Text
                  content="Metodi di pagamento aggiuntivi: "
                  {...readMoreStyle}
                />
                <Text
                  content={
                    additionalPaymentNetworksLabel
                      ? additionalPaymentNetworksLabel
                      : `Apple Pay, GPay`
                  }
                  {...listContentStyle}
                />
              </ReadMoreItem>
            </ReadMoreColumn>
            <ReadMoreColumn>
              {/* <ReadMoreItem>
                  <Icon icon={questionCircle} size={17} />
                  <Text content="Altri costi: " {...readMoreStyle} />
                </ReadMoreItem> */}
              <ReadMoreItem>
                {/* <Icon icon={questionCircle} size={17} /> */}
                <Text content="Tipologia POS: " {...readMoreStyle} />
                <Text
                  content={posTypes.map(id => getPOSType(id)).join(', ')}
                  {...listContentStyle}
                />
              </ReadMoreItem>
            </ReadMoreColumn>
          </ReadMoreWrapper>
          <Text
            content={tableAdditionalInfo}
            {...listContentStyle}
            fontSize="14px"
          />
        </ReadMore>
      </PricingTable>
    )
  );
};

ConfrontaSection.propTypes = {
  sectionWrapper: PropTypes.object,
  row: PropTypes.object,
  col: PropTypes.object,
  secTitleWrapper: PropTypes.object,
  secHeading: PropTypes.object,
  secText: PropTypes.object,
  nameStyle: PropTypes.object,
  descriptionStyle: PropTypes.object,
  priceStyle: PropTypes.object,
  priceLabelStyle: PropTypes.object,
  listContentStyle: PropTypes.object
};

ConfrontaSection.defaultProps = {
  sectionWrapper: {
    as: 'section',
    pt: ['95px', '95px', '95px', '95px', '120px'],
    pb: ['20px', '20px', '20px', '80px']
  },
  sliderWrapper: {
    mt: '3rem',
    pb: ['20px', '20px', '4rem', '4rem']
  },
  row: {
    flexBox: true,
    flexWrap: 'wrap',
    ml: ['-15px', 0],
    mr: ['-15px', 0],
    alignItems: 'center',
    justifyContent: 'space-around'
  },
  secTitleWrapper: {
    mb: ['10px', '10px']
  },
  secText: {
    content: 'CONFRONTA E RISPARMIA',
    as: 'span',
    display: 'block',
    textAlign: 'center',
    fontSize: '13px',
    letterSpacing: '0.15em',
    fontWeight: '700',
    color: '#f50534',
    mb: '10px',
    style: {
      textTransform: 'uppercase'
    }
  },
  secHeading: {
    content: 'Quanto incassi con il POS?',
    textAlign: 'center',
    fontSize: ['34px', '34px', '34px', '34px', '44px'],
    fontWeight: '500',
    color: 'headingColor',
    letterSpacing: '-0.025em',
    mb: '10px'
  },
  col: {
    width: [1, 1 / 2, 1 / 2, 1 / 3],
    pr: '15px',
    pl: '15px'
  },
  nameStyle: {
    fontSize: ['40px', '40px', '40px', '40px', '40px'],
    fontWeight: '500',
    color: 'headingColor',
    letterSpacing: '-0.025em',
    textAlign: 'center',
    mb: '12px',
    style: {
      minHeight: '94px',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center'
    }
  },
  descriptionStyle: {
    fontSize: ['15px', '16px', '16px', '16px', '16px'],
    color: 'textColor',
    lineHeight: '1.75',
    textAlign: 'center',
    mb: '0'
  },
  priceStyle: {
    as: 'span',
    display: 'block',
    fontSize: ['36px', '36px', '40px', '40px', '40px'],
    color: 'headingColor',
    textAlign: 'center',
    mb: '5px',
    letterSpacing: '-0.025em'
  },
  priceLabelStyle: {
    fontSize: ['13px', '14px', '14px', '14px', '14px'],
    color: 'textColor',
    lineHeight: '1.75',
    textAlign: 'center',
    mb: '0'
  },
  buttonStyle: {
    type: 'button',
    style: {
      fontSize: '14px',
      fontWeight: '600',
      borderRadius: '4px',
      maxWidth: '100%',
      textTransform: 'none'
    },
    colors: 'secondaryWithBg'
  },
  buttonFillStyle: {
    type: 'button',
    style: {
      fontSize: '16px',
      fontWeight: '600',
      color: 'white',
      borderRadius: '4px',
      maxWidth: '100%',
      textTransform: 'none'
    },
    colors: 'primaryWithBg'
  },
  listContentStyle: {
    fontSize: ['15px', '16px', '16px', '16px', '16px'],
    color: 'textColor',
    mb: '0'
  },
  readMoreStyle: {
    fontSize: ['15px', '16px', '16px', '16px', '16px'],
    color: '#0f2137',
    mb: '0',
    mr: '5px',
    fontWeight: 700
  }
};

export default ConfrontaSection;
