import React, { useState } from 'react';

import * as S from './readmore.style';

import Icon from 'react-icons-kit';
import AnimateHeight from 'react-animate-height';
import { chevronDown } from 'react-icons-kit/fa/chevronDown';

export default ({ children }) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <S.PricingReadMore>
      <S.ReadMoreHeader onClick={() => setIsOpen(!isOpen)}>
        {`${isOpen ? 'Nascondi' : 'Mostra'}`} dettagli{' '}
        <Icon
          icon={chevronDown}
          size={17}
          style={{
            display: 'flex',
            alignItems: 'center',
            transform: isOpen ? 'rotate(180deg)' : 'none',
          }}
        />
      </S.ReadMoreHeader>
      <AnimateHeight height={isOpen ? 'auto' : 0}>{children}</AnimateHeight>
    </S.PricingReadMore>
  );
};
